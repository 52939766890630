import React, {useState, useEffect, useRef} from "react";
import PropTypes from "prop-types";
import { Input, Label, Icon, Form } from "semantic-ui-react";
import { useIntl } from "react-intl";
const TagsInput = React.forwardRef((props, ref) => {
	const [tags, setTags] = useState([]);
	// const [inputText, setInputText] = useState<string>("");
	const inputRef = useRef();

	const addTags = event => {
		if (event.key === "Enter" && event.target.value !== "") {
			const newTag = event.target.value;
			if (newTag !== "") {
				const newTags = [...tags, newTag];
				setTags(newTags);
				event.target.value = "";
				props.onTagsChange(newTags);
			}
		}
		// if (event.key === "Enter" && inputText !== "") {
		// 	const newTags = [...tags, inputText];
		// 	setTags(newTags);
		// 	setInputText("");
		// 	props.onTagsChange(newTags);
		// }
	};

	const removeTags = index => {
		const newTags = tags.filter((tag, i) => i !== index);
		setTags(newTags);
		props.onTagsChange(newTags);
	};

	React.useImperativeHandle(ref, () => ({
    getTags: () => {
      return tags;
		},
    clear: () => {
      return setTags([]);
    },
  }));
	
	useEffect(() => {
		if (props.tags) {
			// compare array data
			let isEquals = (props.tags.length === tags.length) 
				&& props.tags.every(function(element, index) {
					return element === tags[index];
				})
			if (isEquals) {/* Do nothings */ return;}
			
			// Set tags and clear input
			setTags(props.tags);
			// if (inputRef.current) {
			// 	inputRef.current.inputRef.current.value = "";
			// }
		}
	}, [props.tags])

	return (
		<Form.Field inline
			style={{ 
				background: "white", 
				border: "1px solid rgb(214, 216, 218", 
				borderRadius: "3px",
				display: "flex",
				width: "100%",
				alignItems: "center",
				flexWrap: "wrap"}}
		>

			{tags.map((tag, index) => (
				<Label key={index} style={{margin: "4px"}} size={"small"}>
					{tag}
					<Icon 
						disabled={props.disabled}
						name={"delete"} 
						onClick={() => removeTags(index)}/>
				</Label>
			))}
		
			<Input 
				ref={inputRef}
				className="transparentKeepPadding"
				style={{flex: 1, minWidth: "64px"}}
				type="text"
				size={props.inputSize}
				name={props.inputName}
				onKeyUp={props.readonly ? null : event => addTags(event)}
				placeholder={props.placeholder}
				disabled={props.disabled}
				onChange={props.onChange}
				value={tags.join(",")}
			/>
		
		</Form.Field>
	)
})

TagsInput.defaultProps = {
	inputName: "tags",
	inputSize: "large",
	placeholder: "",
	tags: [],
	disabled: false,
	onChange: (event, data) => {},
	onTagsChange: (data) => {},
	readonly: false,
}

TagsInput.propTypes = {
	inputName: PropTypes.string,
	inputSize: PropTypes.string,
	placeholder: PropTypes.string,
	tags: PropTypes.array,
	disabled: PropTypes.bool,
	onChange: PropTypes.func,
	onTagsChange: PropTypes.func,
	readonly: PropTypes.bool,
}
export default React.memo(TagsInput);